import React from "react";
import {social} from "../../../../shared/EventDetails";
import Intro from "../../../../shared/intro/Intro";

function JeevanathiIntro() {
  return (
     <Intro event={social[0]} />
  );
}

export default JeevanathiIntro;
