import React from "react";
import {
    Grid, Container
} from '@mui/material';
import foodimg from '../../../../images/Events/Card Illustration/foodfestival.png';
import Intro from "../../../../shared/intro/Intro";


function FoodIntro() {
    return (
        <>
            {/* <Intro event=[] */}
        </>
    );
}

export default FoodIntro