import React from 'react';
import './rules.css';
import { Grid, Container, Button } from '@mui/material';
import { useState } from 'react';
const SotkanaiRules = () => {
  const [showRules, setShowRules] = useState(false);

  const handleButtonClick = () => {
    setShowRules(!showRules);
  };
  return (<React.Fragment>
    <div>

      <div className="centerx ">
        <Button variant="contained " className='sotkanai-rules-landing-heading1'
          onClick={handleButtonClick} >சொற்கணைக்கான போட்டி விதிமுறைகள்</Button>
      </div>
      {showRules && (
        <Grid item sm='12'>
          <Container maxWidth="md" >
            <div className="sotkanai-rules-decription">
              <ol type='1'>
                <li>போட்டியில் பங்குபற்றும் அனைத்து மாணவர்களும் 31-01-2004 இற்குப் பின்னர் பிறந்தவர்களாக இருக்க வேண்டும்.</li>
                <li>விண்ணப்பப் படிவத்தில் போட்டியாளர்களின் முழுப்பெயர்கள் ஆங்கிலத்தில் எழுதப்படல் வேண்டும்.</li>
                <li>விண்ணப்பப் படிவத்தை பூர்த்தி செய்து பாடசாலை அதிபரின் கையொப்பத்தோடு போட்டி நடைபெறும் தினத்தன்று மாவட்ட இணைப்பாளரிடம் கையளிக்கவும்.</li>
                <li>உங்கள் பாடசாலையின் வருகையை முன்னதாக மாவட்ட நிலை போட்டி திகதிக்கு ஒரு கிழமைக்கு முன்னதாக மாவட்ட இணைப்பாளருக்கு தொலைபேசி மூலம் அறியத்தரவும்.</li>
                <li>வருகையைக் குறித்த திகதிக்கு முன்னர் உறுதிப்படுத்தாத அணிகள் போட்டியில் கலந்து கொள்ள அனுமதிக்கப்படமாட்டா.</li>
                <li>ஒவ்வொரு அணியும் 4 விவாதிகளையும், 1 உதிரி விவாதியையும் கொண்டிருக்கவேண்டும்.</li>
                <li>தலைவர் உட்பட அனைத்துப் போட்டியாளர்களும் பேசுவதற்கு 4 நிமிடங்களும் இறுதியில் தலைவரின் தொகுப்புரைக்கு 5 நிமிடங்களுமாக ஒரு அணிக்கு
                  மொத்தமாக 21 நிமிடங்கள் வழங்கப்படும்.</li>
                <li>பேச்சுக்கள் தனிப்பட்ட தாக்குதல்களாகவோ, இனம், மதம், பிரிவினைகள் சார்ந்த அவதூறுகளாகவோ அமையக்கூடாது.</li>
                <li>போட்டியில் பங்குபற்றும் அணிகளுக்கான தரப்படுத்தல்கள் யாவும் இசற் புள்ளி (z-score) முறையில் அமைந்த புள்ளியிடல் திட்டத்தினுாடாக மேற்கொள்ளப்படும்.</li>
                <li>விவாதப்போட்டி நடைபெறும் முறை மற்றும் புள்ளியிடல் பற்றிய விரிவான விளக்கம் போட்டித் தினத்தன்று ஆரம்பத்தில் அனைவருக்கும் அறியத்தரப்படும்.</li>
                <li>சுயாதீன நடுவர்கள் குழாத்தின் தீர்ப்பே இறுதியானது. இது தொடர்பான எந்த முறைப்பாடுகளும் ஏற்கப்படாது.</li>
              </ol>
            </div>

          </Container>
        </Grid>
      )}

    </div>
  </React.Fragment>);
}

export default SotkanaiRules;